var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.label ? _c('label', {
    staticClass: "text-sm text-neutral-700 font-medium mb-1 inline-block"
  }, [_vm._v(_vm._s(_vm.label))]) : _vm._e(), _c('input', _vm._b({
    staticClass: "px-3 py-3 focus:outline-none border border-neutral-300 w-full rounded-lg",
    class: [_vm.withIcon ? 'pl-11' : 'pl-3', {
      'is-invalid': _vm.hasErrors,
      'bg-grey cursor-not-allowed text-neutral-300': _vm.disabled
    }],
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('input', $event.target.value);
      },
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.action();
      }
    }
  }, 'input', _vm.$attrs, false)), _vm.notes ? _c('span', {
    staticClass: "text-xs text-gray-500 leading-tight inline-block mt-1"
  }, [_vm._v(_vm._s(_vm.notes))]) : _vm._e(), _c('transition', {
    attrs: {
      "name": "fade",
      "mode": "out-in"
    }
  }, [_vm.error ? _c('div', {
    staticClass: "text-system-error text-left mt-1 text-sm"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }